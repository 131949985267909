@font-face {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Regular"), url("./fonts/AvenirNextLTPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "Avenir Next LT Pro";
  font-style: italic;
  font-weight: normal;
  src: local("Avenir Next LT Pro It"), url("./fonts/AvenirNextLTPro-Italic.woff") format("woff");
}

@font-face {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: bold;
  src: local("Avenir Next LT Pro Demi"), url("./fonts/AvenirNextLTPro-Demi.woff") format("woff");
}

@font-face {
  font-family: "Avenir Next LT Pro";
  font-style: italic;
  font-weight: bold;
  src: local("Avenir Next LT Pro DemiIt"), url("./fonts/AvenirNextLTPro-DemiItalic.woff") format("woff");
}

.font-avenir {
  font-family: "Avenir Next LT Pro", "Helvetica Neue", SFCompactRounded, -apple-system, Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", sans-serif;
}

.font-terminal {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
}

* {
  @apply m-0 p-0;
}

html, body, #root {
  @apply h-full overflow-hidden;
}

body:not(pre) {
  font-family: "Helvetica Neue", SFCompactRounded, -apple-system, Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", sans-serif;
}

.blur {
  backdrop-filter: blur(40px);
}

/* --------- input --------- */

.login input::placeholder {
  color: white;
}

.spotlight input::placeholder {
  @apply text-black opacity-40;
}

/* --------- spotlight --------- */

.spotlight {
  width: 660px;
  margin-left: -330px;
  left: 50%;
}

@media (max-width: 639px) {
  .spotlight {
    width: 100%;
    margin-left: 0;
    left: 0;
  }
}

/* --------- app window --------- */

.window-bound {
  width: 300%;
  height: 200%;
  left: -100%;
}

.innner-window {
  height: calc(100% - 1.5rem);
}

.safari-content {
  height: calc(100% - 2rem);
}

.traffic_lights svg {
  @apply hidden;
}

.traffic_lights:hover svg {
  @apply block;
}

/* --------- dock --------- */

.dock ul {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.17);
}

.dock li .tooltip {
  @apply hidden;
  top: calc(-100% - 5px);
}

.dock li:hover .tooltip {
  @apply block;
  box-shadow: hsla(0deg, 0%, 0%, 20%) 0px 1px 5px 2px, 0 0 0 0 white;
}

/* --------- control center --------- */

.control-grid {
  @apply bg-gray-200 bg-opacity-80 blur rounded-xl;
  backdrop-filter: blur(40px);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

/* --------- slider --------- */

.slider .rangeslider {
  @apply bg-gray-300 w-full h-7 shadow-none m-0 rounded-r-full border-t border-r border-b border-transparent;
}

.slider .rangeslider .rangeslider__fill {
  @apply bg-gray-100 shadow-none rounded-none;
}

.slider .rangeslider .rangeslider__handle {
  @apply border border-gray-300 outline-none h-7 w-7;
  background-color: #fff;
  color: #fff;
  box-shadow: -3px 0px 8px 0px rgba(17, 17, 17, 0.15);
}

.slider .rangeslider .rangeslider__handle::after {
  @apply hidden;
}

/* --------- bear markdown --------- */

.bear .markdown a {
  @apply text-red-500;
}

.bear .markdown h1,
.bear .markdown h2,
.bear .markdown h3,
.bear .markdown h4 {
  @apply font-bold relative text-gray-800;
}

.bear .markdown h1,
.bear .markdown h2,
.bear .markdown h3,
.bear .markdown h4 {
  @apply mt-7;
}

.bear .markdown h1::before,
.bear .markdown h2::before,
.bear .markdown h3::before,
.bear .markdown h4::before {
  @apply absolute text-sm text-gray-400 -left-7 bottom-1;
}

.bear .markdown h1 {
  @apply text-3xl;
}

.bear .markdown h1::before {
  content: "H1";
}

.bear .markdown h2 {
  @apply text-2xl;
}

.bear .markdown h2::before {
  content: "H2";
}

.bear .markdown h3 {
  @apply text-xl;
}

.bear .markdown h3::before {
  content: "H3";
}

.bear .markdown h3 {
  @apply text-xl;
}

.bear .markdown h4 {
  @apply text-lg;
}

.bear .markdown h4::before {
  content: "H4";
}

.bear .markdown p {
  @apply mt-6;
}

.bear .markdown h2 + p,
.bear .markdown h3 + p,
.bear .markdown h4 + p {
  @apply mt-4;
}

.bear .markdown ul,
.bear .markdown ol {
  @apply mt-2 pl-5;
}

.bear .markdown ul {
  @apply list-disc;
}

.bear .markdown ul > li::marker {
  @apply text-red-500;
}

.bear .markdown ol {
  @apply list-decimal;
}

.bear .markdown li p {
  @apply m-0 mb-2;
}

.bear .markdown code {
  @apply text-sm border border-gray-300 rounded py-0.5 px-1 bg-gray-50;
}

.bear .markdown pre {
  @apply mt-2 text-sm;
}

.bear .markdown pre code {
  @apply text-sm;
}

.dark .bear .markdown pre code {
  font-size: 14px !important;
}

.bear .markdown pre div {
  @apply border border-gray-300 rounded;
}

.bear .markdown pre code,
.dark .bear .markdown pre code {
  @apply text-base bg-transparent border-none p-0;
}

.bear .markdown .token {
	background: transparent !important;
}

.bear .markdown img {
  @apply inline-block;
}

.bear .markdown table {
  @apply mt-4 block w-full overflow-auto;
}

.bear .markdown table td,
.bear .markdown table th {
  @apply px-3 py-2 border border-gray-300;
}

.bear .markdown table tr {
  @apply border-t border-gray-300 bg-transparent;
}

.dark .bear .markdown,
.dark .bear .midbar {
  @apply bg-gray-800;
}

.dark .bear .midbar li.bg-white {
  @apply bg-gray-900;
}

.dark .bear .midbar li:hover {
  @apply bg-gray-900;
}

.dark .bear .sidebar {
  @apply bg-gray-700 text-white;
}


/*
  Credits: W3 Schools: https://www.w3schools.com/howto/howto_css_switch.asp
*/

/* The switch - the box around the slider */
.switch-toggle {
  width: 38px;
  @apply relative inline-block h-full;
}

/* Hide default HTML checkbox */
.switch-toggle input {
  @apply w-0 h-0 opacity-0;
}

/* The slider */
.slider-toggle {
  @apply absolute rounded-full bg-gray-300 top-0 left-0 right-0 bottom-0 cursor-pointer duration-300;
}

.slider-toggle:before {
  content: "";
  background-color: white;
  height: 18px;
  width: 18px;
  bottom: 2px;
  left: 2px;
  @apply absolute rounded-full duration-300;
}

input:checked + .slider-toggle {
  background-color: #2196F3;
}

input:focus + .slider-toggle {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider-toggle:before {
  @apply transform translate-x-4;
}
